import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService, NotificationStatus} from '../../services/notification.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-set-reset-password',
  templateUrl: './set-reset-password.component.html',
  styleUrls: ['./set-reset-password.component.scss']
})
export class SetResetPasswordComponent implements OnInit {
  isShowNewPass = true;
  isShowConfPass = true;
  resetPassForm;
  resetToken;
  status;
  userId;
  constructor(private _authService: AuthService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private _notificationService: NotificationService) { }

  ngOnInit(): void {
    if (this._authService.getToken() && this._authService.getUser()) {
      this.router.navigate(['dashboard'])
    } else {
      this.status = this.activeRoute.snapshot.routeConfig.path.split("/")[0];
    }
    if (this.status === 'set' || this.status === 'reset') {
      this.userId = this.activeRoute.snapshot.params.id;
      this.resetToken = this.activeRoute.snapshot.queryParams["token"];
    }
    this.resetPassForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('',[Validators.required])
    })
  }
  toggleShowPassword(type, element) {
    switch (type) {
      case 'new':
        this.isShowNewPass = !this.isShowNewPass;
        if (this.isShowNewPass) {
          element.setAttribute('type', 'password');
        } else {
          element.setAttribute('type', 'text');
        }
        break;
      case 'confirm':
        this.isShowConfPass  =! this.isShowConfPass
        if (this.isShowConfPass) {
          element.setAttribute('type', 'password');
        } else {
          element.setAttribute('type', 'text');
        }
    }
  }
  onsetPassword() {
    if (this.resetPassForm.valid) {
      if (!this.resetPassForm.controls['password'].value.trim().length) {
        this._notificationService.showNotification( NotificationStatus.ERROR, 'Please enter valid password.');
        this.resetPassForm.controls['password'].setErrors({incorrect: true});
        return;
      }
      if (!this.resetPassForm.controls['confirmPassword'].value.trim().length) {
        this._notificationService.showNotification( NotificationStatus.ERROR, 'Confirm password should match new password');
        this.resetPassForm.controls['confirmPassword'].setErrors({incorrect: true});
        return;
      }
      if (this.resetPassForm.controls['confirmPassword'].value !== this.resetPassForm.controls['password'].value) {
        this._notificationService.showNotification( NotificationStatus.ERROR, 'Confirm password should match new password');
        this.resetPassForm.controls['confirmPassword'].setErrors({incorrect: true});
        return;
      }
    } else {
      if (this.resetPassForm.controls['password'].invalid) {
        this._notificationService.showNotification(NotificationStatus.ERROR, 'Please enter a valid password.');
        return;
      }
      if (this.resetPassForm.controls['confirmPassword'].invalid) {
        this._notificationService.showNotification( NotificationStatus.ERROR, 'Confirm password should match new password');
        return;
      }
    }
    let data = {
      password: this.resetPassForm.controls['password'].value,
      token: this.resetToken
    }
    switch (this.status) {
      case 'reset':
        this._authService.resetPassword(data, this.userId).then((res:any) => {
          this._notificationService.showNotification(NotificationStatus.SUCCESS, res.message );
          this.router.navigate(["login"]);
        })
          .catch(err => {
            console.log(err);
            this._notificationService.showNotification( NotificationStatus.ERROR, err.error.message ? err.error.message : 'Something went wrong')
          })
        break;
      case 'set':
        let data1 = {
          password: this.resetPassForm.controls['password'].value,
          newUserToken: this.resetToken
        }
        this._authService.setPassword(data1, this.userId).then((res:any) => {
          this._notificationService.showNotification(NotificationStatus.SUCCESS, res.message );
          this.router.navigate(["login"]);
        })
          .catch(err => {
            console.log(err);
            this._notificationService.showNotification( NotificationStatus.ERROR, err.error.message ? err.error.message : 'Something went wrong')
          })
        break;
    }
  }
  goToLogin() {
    this.router.navigate(['login']);
  }
}
