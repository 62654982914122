import { Injectable } from '@angular/core';
import { NotificationService, NotificationStatus } from './notification.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
declare const ExcelJS: any;

@Injectable()
export class ExcelService {
  constructor(private notificationService: NotificationService) {}

  generateExcel({ data, columns, name }: { data: any; columns: Array<string>; name: string }): void {
    if (data && data.length < 1) {
      return this.notificationService.showNotification(NotificationStatus.WARNING, 'There is no data to download');
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(name);
    worksheet.addRow(columns);
    columns.forEach((column, index) => {
      worksheet.getColumn(index + 1).width = 30;
    });
    worksheet.getRow(1).eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: 'FFFFFFFF' },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF666666' },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.forEach((row, rowIndex) => {
      getRowInsert = worksheet.getRow(newRowNumber);
      const dataKeys = Object.keys(row);
      dataKeys.forEach((key, index) => {
        getRowInsert.getCell(index + 1).value = row[key] ? row[key] : '-';
      });
      getRowInsert.eachCell((cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'left' };
      });
      newRowNumber++;
    });
    try {
      workbook.xlsx.writeBuffer().then((buffer) => {
        // tslint:disable-next-line:no-shadowed-variable
        const excelFileData: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        const url = window.URL.createObjectURL(excelFileData);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', name + EXCEL_EXTENSION);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    } catch (e) {
      console.log(e);
    }
  }
}
