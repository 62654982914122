import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {NotificationService, NotificationStatus} from '../../services/notification.service';
import {slideUpAnimation} from '../../animations/slide-up.animation';
import {animate, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations:[trigger('slideUpAnimation', [

    // route 'enter' transition
    transition(':enter', [

      // css styles at start of transition
      style({ transform: 'translateY(100%)'
      }),

      // animation and styles at end of transition
      animate('3s ease-in-out', style({
        transform: 'translateY(0)'
      }))
    ]),
  ])]
})
export class LoginComponent implements OnInit {
  showPassword = true;
  loginForm;
  status;
  loading = false;
  constructor(private router: Router,
              private activeRoute: ActivatedRoute,
              private _authService: AuthService,
              private _notificationService: NotificationService) { }

  ngOnInit(): void {
    if (this._authService.getToken() && this._authService.getUser()) {
      this.router.navigate(['dashboard'])
    } else {
      if (this.activeRoute.snapshot.routeConfig.path.split("/")[0]) {
        this.status = this.activeRoute.snapshot.routeConfig.path.split("/")[0];
      } else {
        this.status = "login";
      }

    }
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('',[Validators.required])
    })
  }

  onLogin() {
    this.loading = true;
    if (this.loginForm.valid) {
      if (!this.loginForm.controls['password'].value.trim().length) {
        this._notificationService.showNotification( NotificationStatus.ERROR, 'Please enter valid password.');
        this.loginForm.controls['password'].setErrors({incorrect: true});
        return;
      }
      this._authService.login(this.loginForm.value)
        .then(res => {
          this.router.navigate(['dashboard']);
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this._notificationService.showNotification(NotificationStatus.ERROR, err.error.message ? err.error.message : 'Something went wrong')
        })
    } else {
      this.checkLoginValidation()
    }
  }
  toggleShowPassword(type, element) {
        this.showPassword = !this.showPassword;
        if (this.showPassword) {
          element.setAttribute('type', 'password');
        } else {
          element.setAttribute('type', 'text');
        }
  }

  checkLoginValidation() {
    if (this.loginForm.controls['email'].invalid) {
      this._notificationService.showNotification(NotificationStatus.ERROR, 'Please enter a valid email address.');
    }
    if (this.loginForm.controls['password'].invalid) {
      this._notificationService.showNotification( NotificationStatus.ERROR, 'Please enter a valid password.');
    }
  }

  goToForgotPassword() {
    this.router.navigate(['forgot']);
  }
}
