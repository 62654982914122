import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {SetResetPasswordComponent} from './components/set-reset-password/set-reset-password.component';
import {AuthGuard} from './utils/guard/auth.guard';

const routes: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "forgot",
    component: ForgotPasswordComponent
  },
  {
    path: "reset/:id",
    component: SetResetPasswordComponent
  },
  {
    path: "set/:id",
    component: SetResetPasswordComponent
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
