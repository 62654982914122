import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbMenuModule, NbThemeModule, NbToastrService } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { NebularModule } from './nebular.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './utils/interceptor/token.interceptor';
import { NotificationService } from './services/notification.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SetResetPasswordComponent } from './components/set-reset-password/set-reset-password.component';
import { AuthGuard } from './utils/guard/auth.guard';
import { ExcelService } from './services/excel-file.service';

@NgModule({
  declarations: [AppComponent, LoginComponent, ForgotPasswordComponent, SetResetPasswordComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbEvaIconsModule,
    AppRoutingModule,
    NebularModule,
    NbMenuModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    NotificationService,
    ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
