import {NgModule} from '@angular/core';
import {
  NbAccordionModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbToastrModule,
  NbToastrService,
  NbToggleModule,
  NbTooltipModule
} from '@nebular/theme';

@NgModule({
 imports: [
   NbInputModule,
   NbLayoutModule,
   NbButtonModule,
   NbIconModule,
   NbTooltipModule,
   NbCheckboxModule,
   NbFormFieldModule,
   NbSelectModule,
   NbToggleModule,
   NbContextMenuModule,
   NbMenuModule,
   NbToastrModule.forRoot(),
   NbAutocompleteModule,
   NbSpinnerModule,
   NbCardModule,
   NbDialogModule.forChild(),
   NbAccordionModule,
   NbListModule,
   NbTabsetModule
 ],
  exports: [
    NbInputModule,
    NbLayoutModule,
    NbButtonModule,
    NbIconModule,
    NbTooltipModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbSelectModule,
    NbToggleModule,
    NbContextMenuModule,
    NbToastrModule,
    NbAutocompleteModule,
    NbSpinnerModule,
    NbCardModule,
    NbDialogModule,
    NbAccordionModule,
    NbListModule,
    NbTabsetModule
  ]
})
export class NebularModule {}
