import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {
  constructor(private toastService: NbToastrService) {}
  showNotification(status, message): void {
    this.toastService.show(message, null, {
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      status,
    });
  }
}
export enum NotificationStatus {
  ERROR = 'danger',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
}
