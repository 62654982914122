import {environment} from '../../environments/environment';

export class Urls {
  static baseUrl = environment.apiUrl;
  static auth = Urls.baseUrl + "auth";
  static login = Urls.baseUrl + "auth/login";
  static logout = Urls.baseUrl + "auth/logout";
  static forgotPassword = Urls.baseUrl + "auth/forgot-password";
  static accounts = Urls.baseUrl + "users/global";
  static fileUpload = Urls.baseUrl + "images/upload";
  static images = Urls.baseUrl + "images";
  static staffAccounts = Urls.baseUrl + "users/staffAccounts";
  static roles = Urls.baseUrl + "roles";
}
